
import area from '@turf/area';
import booleanIntersects from '@turf/boolean-intersects';
import convex from '@turf/convex';
import { Feature, LineString, Position } from '@turf/helpers';
import * as turf from '@turf/turf';
import union from '@turf/union';
import {
  Activity,
  FieldUtilization,
  GeoObject,
  GeoObjectType,
  LandCadastre,
  PlantVariety,
  Polygon,
} from 'farmdok-rest-api';
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';

import { Company } from '@/auth/store/types';
import { FieldsState } from '@/fields/store/types';
import toGoogleMapsPath from '@/fields/utils/toGoogleMapsPath';
import { GeoObjectState } from '@/geoObjects/store/types';
import { reportState } from '@/map/composables/useReportState';
import { Api } from '@/plugins/farmdokRestApi';
import { ActivityType, Customer, Field, Product } from '@/shared/api/rest/models';
import { FieldContour, GeoJson } from '@/shared/api/rest/models/field';
import { Fields } from '@/shared/components/map/types';
import { UUID5_FIELD_CONTOUR_NODE } from '@/shared/constants';
import { Data } from '@/shared/mixins/store/types';
import isUnique from '@/shared/modules/isUniqueFilter';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { createUuid, createUuidv5 } from '@/shared/modules/uuid';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import { RootState } from '@/store/types';

import FieldsMap from '../components/FieldsMap.vue';
import { SelectionSource } from '../types';
import isValidPolygon from '../utils/validatePolygon';

export default defineComponent({
  name: 'FieldsMapContainer',
  components: { FieldsMap },
  props: {
    selectedFields: {
      type: Array as PropType<string[]>,
      required: true,
    },
    visibleFields: {
      type: Array as PropType<string[]>,
      required: true,
    },
    fields: {
      type: Object as PropType<Fields>,
      required: true,
    },
    createFieldsActive: {
      type: Boolean,
      default: false,
    },
    editFieldsActive: {
      type: Boolean,
      default: false,
    },
    createReportActive: {
      type: Boolean,
      default: false,
    },
    newFields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    invalidNewFields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    invalidEditFields: {
      type: Array as PropType<Field[]>,
      default: () => [],
    },
    createMapObjectActive: {
      type: Boolean,
      default: false,
    },
    editMapObjectActive: {
      type: Boolean,
      default: false,
    },
    currentFieldId: {
      type: String,
      default: null,
    },
    geoDataChanges: {
      type: Object,
      default: () => {},
    },
    selectionSource: {
      type: String as PropType<SelectionSource>,
      default: 'MAP',
    },
    checkDistance: {
      type: Number,
      default: 200,
    },
    checkDistanceActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reportState,
      landCadastres: {} as Data<LandCadastre>,
      fieldUtilization: {} as Data<FieldUtilization>,
      loadedBoundingBoxLandCadastres: null as google.maps.LatLngBounds | null,
      loadedBoundingBoxFieldUtilization: null as google.maps.LatLngBounds | null,
      cadastresLoading: false,
      fieldUtilizationLoading: false,
      reCenterOnFieldUpdate: true,

      // split
      splitFieldEnabled: false,
      splitFieldLineValid: false,
      newFieldA: null as any,
      newFieldB: null as any,
      ndviRequested: false,
      mbiRequested: false,
    };
  },
  async created() {
    this.$store.dispatch('varieties/subscribe');
    this.$store.dispatch('products/crops/subscribe');
  },
  methods: {
    updateSplitFieldEnabled(active: boolean) {
      this.splitFieldEnabled = active;
    },
    split() {
      const newFields = [this.newFieldA, this.newFieldB];
      this.$emit('update:newFields', newFields);
      this.splitFieldEnabled = false;
    },
    updateNewFieldPolygons({ geoJsonA, geoJsonB }: any) {
      this.newFieldA = this.getNewFieldForGeoJson(geoJsonA);
      this.newFieldB = this.getNewFieldForGeoJson(geoJsonB);
    },
    getNewFieldForGeoJson(geoJson: any) {
      const [lon, lat] = geoJson.coordinates[0].reduce(
        ([totalLon, totalLat]: number[], [nextLon, nextLat]: number[]) => [totalLon + nextLon, totalLat + nextLat],
        [0, 0],
      );
      const fieldSize = Math.floor(area(geoJson)) / 10000;
      return {
        ...this.newFields[0], // when splitting, only one field is allowed, so we can use the zero index
        id: createUuid(),
        lon: lon / geoJson.coordinates[0].length,
        lat: lat / geoJson.coordinates[0].length,
        fieldContour: {
          geoJson,
          area: fieldSize,
        },
        fieldSize,
      };
    },
    async loadActivities() {
      await this.$store.dispatch('activities/subscribe');
      await this.$store.dispatch('activityTypes/subscribe');
    },
    onPolygonClicked(fieldId: string) {
      if (this.disabledFields[fieldId] != null || this.createReportActive) {
        return;
      }

      if (this.createFieldsActive || this.editFieldsActive) {
        this.$emit('update:currentFieldId', fieldId);
      } else {
        const updatedSelectedFields = [...this.selectedFields];
        const index = updatedSelectedFields.indexOf(fieldId);

        if (index !== -1) {
          updatedSelectedFields.splice(index, 1);
        } else {
          updatedSelectedFields.push(fieldId);
        }

        this.$emit('update:selectedFields', updatedSelectedFields);
      }
    },
    async loadNdvi(fields: Field[]) {
      if (this.featureFieldsNDVIMeanEnabled && !this.loadingNdvi) {
        this.$store.dispatch('fields/loadNdvi', fields);
      }
    },
    async loadMbi(fields: Field[]) {
      if (this.featureFieldsNDVIMeanEnabled && !this.loadingMbi) {
        this.$store.dispatch('fields/loadMbi', fields);
      }
    },
    async loadFieldUtilization(bounds: google.maps.LatLngBounds) {
      if (!bounds) {
        return;
      }

      if (this.fieldUtilizationLoading) {
        return;
      }

      if (this.isBoundsCovered(bounds, this.loadedBoundingBoxFieldUtilization)) {
        return;
      }

      if (Object.values(this.fieldUtilization).length > 1000) {
        this.fieldUtilization = {};
        this.loadedBoundingBoxFieldUtilization = null;
      }

      const bJson = bounds.toJSON();
      const bbox = [bJson.east, bJson.north, bJson.west, bJson.south];
      const { mapApi } = this.$api as Api;

      this.fieldUtilizationLoading = true;
      const response = await mapApi.mapLayerFieldUtilization({ bbox: `[${bbox}]` });

      if (response.data.status === 'success') {
        this.loadedBoundingBoxFieldUtilization = bounds;

        const fieldUtilizationResponseData = response.data.data as FieldUtilization[];
        const fieldUtilization: Data<FieldUtilization> = {};
        fieldUtilizationResponseData.forEach((fieldUtil) => {
          fieldUtilization[fieldUtil.id] = fieldUtil;
        });
        this.fieldUtilization = fieldUtilization;

        this.fieldUtilizationLoading = false;
      }
    },
    async loadLandCadastres(bounds: google.maps.LatLngBounds) {
      if (!bounds || !this.featureLandCadastresEnabled) {
        return;
      }

      if (this.cadastresLoading) {
        return;
      }

      if (this.isBoundsCovered(bounds, this.loadedBoundingBoxLandCadastres)) {
        return;
      }

      const bJson = bounds.toJSON();
      const bbox = [bJson.east, bJson.north, bJson.west, bJson.south];
      const { mapApi } = this.$api as Api;

      this.cadastresLoading = true;
      const response = await mapApi.mapLayerLandCadastre({ bbox: `[${bbox}]` });

      if (response.data.status === 'success') {
        this.loadedBoundingBoxLandCadastres = bounds;
        const landCadastresResponseData = response.data.data as LandCadastre[];
        const landCadastres: Data<LandCadastre> = {};
        landCadastresResponseData.forEach((landCadastre) => {
          landCadastres[landCadastre.id] = landCadastre;
        });
        this.landCadastres = landCadastres;
      }
      this.cadastresLoading = false;
    },
    isBoundsCovered(bounds1: google.maps.LatLngBounds, bounds2: google.maps.LatLngBounds | null) {
      if (bounds2) {
        const ne1 = bounds1.getNorthEast();
        const sw1 = bounds1.getSouthWest();
        const ne2 = bounds2.getNorthEast();
        const sw2 = bounds2.getSouthWest();

        return ne1.lat() <= ne2.lat() && ne1.lng() <= ne2.lng() && sw1.lat() >= sw2.lat() && sw1.lng() >= sw2.lng();
      }

      return false;
    },
    insert(field: any) {
      let fieldContour = null;
      let fieldSize = null;
      if (field.fieldContour != null) {
        fieldSize = Math.floor(area(field.fieldContour.geoJson)) / 10000;
        fieldContour = {
          ...field.fieldContour,
        };
      }
      const id = createUuid();
      const newField = {
        ...field,
        'fieldGroup.mfa': '',
        name: '',
        fieldSize,
        cropId: null,
        id,
        fieldContour,
        placeholderName: this.$t('Feld {number}', { number: this.newFields.length + 1 }),
      };
      const newFields = [...this.newFields, newField];

      const isValid = isValidPolygon(fieldContour.geoJson.coordinates);
      if (isValid) {
        if (this.invalidNewFields.some((f) => f.id === field.id)) {
          this.$emit(
            'update:invalidNewFields',
            this.invalidNewFields.filter((f) => f.id !== field.id),
          );
        }
      } else {
        this.$emit('update:invalidNewFields', [...this.invalidNewFields, newField]);
      }

      this.$emit('update:newFields', newFields);
      this.$emit('update:currentFieldId', id);
    },
    addPolygon(field: any) {
      let fieldContour: any = null;
      let fieldSize: any = null;
      if (field.fieldContour != null) {
        fieldSize = Math.floor(area(field.fieldContour.geoJson)) / 10000;
        fieldContour = {
          ...field.fieldContour,
          id: createUuidv5(UUID5_FIELD_CONTOUR_NODE, field.id),
        };
      }

      const isValid = isValidPolygon(fieldContour.geoJson.coordinates);
      if (!isValid) {
        this.$emit('update:invalidEditFields', [...this.invalidEditFields, field]);
      } else {
        this.$emit(
          'update:invalidEditFields',
          this.invalidEditFields.filter((f) => f.id !== field.id),
        );
      }

      const localGeoDataChanges = { ...this.geoDataChanges };
      if (localGeoDataChanges[field.id] == null) {
        this.$emit('update:geoDataChanges', {
          ...this.geoDataChanges,
          [field.id]: { fieldContour, fieldSize },
        });
      } else {
        localGeoDataChanges[field.id] = { fieldContour, fieldSize };
        this.$emit('update:geoDataChanges', localGeoDataChanges);
      }
    },
    changePolygon({ fieldContour, fieldId }: { fieldContour: FieldContour; fieldId: string }) {
      if (this.createFieldsActive) {
        const fieldSize = Math.floor(area(fieldContour.geoJson)) / 10000;
        const newFields = this.newFields.map((field) => {
          if (field.id !== fieldId) {
            return field;
          }

          const isValid = isValidPolygon(fieldContour.geoJson.coordinates);
          if (isValid) {
            if (this.invalidNewFields.some((f) => f.id === field.id)) {
              this.$emit(
                'update:invalidNewFields',
                this.invalidNewFields.filter((f) => f.id !== field.id),
              );
            }
          } else {
            this.$emit('update:invalidNewFields', [...this.invalidNewFields, field]);
          }

          return {
            ...field,
            fieldSize,
            fieldContour: {
              ...field.fieldContour,
              geoJson: fieldContour.geoJson,
              contourSize: fieldSize,
            },
          };
        });
        this.$emit('update:newFields', newFields);
      } else if (this.editFieldsActive) {
        const fieldSize = Math.floor(area(fieldContour.geoJson)) / 10000;
        const isValid = isValidPolygon(fieldContour.geoJson.coordinates);
        const field = this.fields[fieldId];

        if (!isValid) {
          this.$emit('update:invalidEditFields', [...this.invalidEditFields, field]);
        } else {
          this.$emit(
            'update:invalidEditFields',
            this.invalidEditFields.filter((f) => f.id !== fieldId),
          );
        }

        const newFieldContour = {
          ...field.fieldContour,
          geoJson: fieldContour.geoJson,
          contourSize: fieldSize,
          tstamp: Math.floor(new Date().getTime() / 1000),
        };

        const localGeoDataChanges = { ...this.geoDataChanges };
        if (localGeoDataChanges[fieldId] == null) {
          this.$emit('update:geoDataChanges', {
            ...this.geoDataChanges,
            [fieldId]: { fieldContour: newFieldContour, fieldSize },
          });
        } else {
          localGeoDataChanges[fieldId] = { fieldContour: newFieldContour, fieldSize };
          this.$emit('update:geoDataChanges', localGeoDataChanges);
        }
      }
    },
    changeMarker({ lat, lon, fieldId }: { lat: number; lon: number; fieldId: string }) {
      if (this.createFieldsActive) {
        const newFields = this.newFields.map((field) => {
          if (field.id !== fieldId) {
            return field;
          }
          return {
            ...field,
            lat,
            lon,
          };
        });
        this.$emit('update:newFields', newFields);
        return;
      }

      if (this.editFieldsActive) {
        const localGeoDataChanges = { ...this.geoDataChanges };
        if (localGeoDataChanges[fieldId] === null) {
          this.$emit('update:geoDataChanges', {
            ...this.geoDataChanges,
            [fieldId]: { lat, lon },
          });
        } else {
          localGeoDataChanges[fieldId] = { lat, lon };
          this.$emit('update:geoDataChanges', localGeoDataChanges);
        }
      }
    },
    changeFieldName({ fieldId, name }: { fieldId: string; name: string }) {
      const newFields = this.newFields.map((field) => {
        if (field.id !== fieldId) {
          return field;
        }
        return {
          ...field,
          name,
        };
      });
      this.$emit('update:newFields', newFields);
    },
    fieldSave(fieldId: string) {
      if (this.currentFieldId === fieldId) {
        this.$emit('update:currentFieldId', null);
      }
    },
    onRemovePolygon(fieldId: string) {
      if (this.createFieldsActive) {
        const newFields = this.newFields.map((field: any) => {
          if (field.id !== fieldId) {
            return field;
          }
          return {
            ...field,
            fieldContour: null,
          };
        });
        this.$emit('update:newFields', newFields);
      } else if (this.editFieldsActive) {
        const field = this.fields[fieldId];
        const localGeoDataChanges = { ...this.geoDataChanges };
        if (localGeoDataChanges[fieldId] == null) {
          this.$emit('update:geoDataChanges', {
            ...this.geoDataChanges,
            [fieldId]: { fieldContour: null, fieldSize: null },
          });
        } else {
          localGeoDataChanges[fieldId] = { fieldContour: null, fieldSize: null };
          this.$emit('update:geoDataChanges', localGeoDataChanges);
        }
        this.$emit('update:invalidEditFields', [...this.invalidEditFields, field]);
      }
    },
    fieldDiscard(fieldId: string) {
      if (this.currentFieldId === fieldId) {
        this.$emit('update:currentFieldId', null);
      }
      const newFields = this.newFields
        .filter((field) => field.id !== fieldId)
        .map((field, index) => ({
          ...field,
          placeholderName: this.$t('Feld {number}', { number: index + 1 }),
        }));
      this.$emit('update:newFields', newFields);
      this.$emit(
        'update:invalidNewFields',
        this.invalidNewFields.filter((field) => field.id !== fieldId),
      );
    },
    handleFeatureClick({
      feature,
      latLng,
      data,
    }: {
      feature: google.maps.Data.Feature;
      latLng: google.maps.LatLng;
      data: Data<LandCadastre> | Data<FieldUtilization>;
    }) {
      if (this.createFieldsActive) {
        const cadastre = data[feature.getId() as number];
        if (cadastre) {
          const geoJson = { geoJson: cadastre.geometry };
          const newField: {
            lat: number;
            lon: number;
            fieldContour?: { geoJson: Polygon };
            additionalData: {
              cadastralCommunity: string;
              plots: string;
            };
          } = {
            lat: latLng.lat(),
            lon: latLng.lng(),
            fieldContour: geoJson,
            additionalData: {
              cadastralCommunity: 'cadastralMunicipality' in cadastre ? cadastre.cadastralMunicipality : '',
              plots: 'parcelNumber' in cadastre ? cadastre.parcelNumber : '',
            },
          };

          this.insert(newField);
        }
      }
    },
    polygonComplete(gmapsPolygon: google.maps.Polygon) {
      const newField: {
        lat?: number;
        lon?: number;
        fieldContour?: { geoJson: { coordinates: number[][][]; type: string } };
      } = {};

      const bounds = new google.maps.LatLngBounds();
      const coordinates: [number, number][] = [];

      gmapsPolygon
        .getPath()
        .getArray()
        .forEach((point) => {
          bounds.extend(point);
          coordinates.push([point.lng(), point.lat()]);
        });

      newField.fieldContour = { geoJson: { coordinates: [coordinates], type: 'Polygon' } };

      const center = bounds.getCenter();
      newField.lat = center.lat();
      newField.lon = center.lng();
      this.insert(newField);
    },
    combinePolygons() {
      type AdditionalProperties = {
        cadastralCommunity: string[];
        plots: string[];
      };

      type TurfFeature = turf.Feature<turf.Polygon | turf.MultiPolygon, AdditionalProperties>;

      const polygons = Object.values(this.newFields)
        .filter(({ fieldContour }) => fieldContour != null)
        .filter(({ fieldContour }) => fieldContour.geoJson != null)
        .filter(({ fieldContour }) => fieldContour.geoJson.type === 'Polygon')
        .map<TurfFeature>(({ fieldContour, additionalData }) =>
          turf.polygon<AdditionalProperties>(fieldContour.geoJson.coordinates, {
            plots: (additionalData.plots as string).split(', '),
            cadastralCommunity: (additionalData.cadastralCommunity as string).split(', '),
          }),
        );

      if (polygons.length === 0) {
        return;
      }

      let combinedPolygons = polygons.reduce<TurfFeature | null>((previousPolygon, currentPolygon) => {
        if (previousPolygon == null) {
          return currentPolygon;
        }

        const reducedPlotNumbers = [...previousPolygon.properties.plots, ...currentPolygon.properties.plots];
        const reducedCadastralCommunities = [
          ...previousPolygon.properties.cadastralCommunity,
          ...currentPolygon.properties.cadastralCommunity,
        ];
        return union<AdditionalProperties>(previousPolygon, currentPolygon, {
          properties: {
            plots: reducedPlotNumbers,
            cadastralCommunity: reducedCadastralCommunities,
          },
        });
      }, null);

      if (combinedPolygons && combinedPolygons.geometry.type !== 'Polygon') {
        const { properties } = combinedPolygons;
        // there's a bug in the convex function where the properties are not populated https://github.com/Turfjs/turf/blob/master/packages/turf-convex/index.ts, I'll report to their GitHub and fix it soon
        combinedPolygons = convex<AdditionalProperties>(combinedPolygons.geometry, {
          properties: combinedPolygons?.properties,
        });

        if (combinedPolygons) {
          combinedPolygons.properties = properties;
        }
      }

      if (!combinedPolygons || combinedPolygons.geometry.type !== 'Polygon') {
        return;
      }

      const [lon, lat] = combinedPolygons.geometry.coordinates[0].reduce(
        ([totalLon, totalLat]: number[], [nextLon, nextLat]: number[]) => [totalLon + nextLon, totalLat + nextLat],
        [0, 0],
      );
      const fieldSize = Math.floor(area(combinedPolygons.geometry)) / 10000;
      const newField = {
        ...this.newFields[0],
        id: createUuid(),
        lon: lon / combinedPolygons.geometry.coordinates[0].length,
        lat: lat / combinedPolygons.geometry.coordinates[0].length,
        fieldContour: {
          geoJson: combinedPolygons.geometry,
          area: fieldSize,
          tstamp: Math.floor(new Date().valueOf() / 1000),
        },
        additionalData: {
          plots: combinedPolygons.properties.plots.filter(isUnique).join(', '),
          cadastralCommunity: combinedPolygons.properties.cadastralCommunity.filter(isUnique).join(', '),
        },
        fieldSize,
      };

      this.$emit('update:newFields', [newField]);
    },
    intersectionWithFieldBuffers(geometry: Polygon): boolean {
      return this.bufferFeatures.some((feature: Feature<turf.Polygon>) => booleanIntersects(geometry, feature));
    },
  },
  computed: {
    ...mapState('fields', {
      loadingNdvi(state: FieldsState): boolean {
        return state.loadingNdvi;
      },
      loadingMbi(state: FieldsState): boolean {
        return state.loadingMbi;
      },
    }),
    pendingNdvi(): Field[] {
      if (this.ndviRequested) {
        return Object.values(this.fields).filter((field: Field) => field.ndvi === undefined);
      }
      return [];
    },
    pendingMbi(): Field[] {
      if (this.mbiRequested) {
        return Object.values(this.fields).filter((field: Field) => field.mbi === undefined);
      }
      return [];
    },
    ...mapState('geoObjects', {
      currentMapObjectType(state: GeoObjectState): GeoObjectType {
        return state.currentObjectType;
      },
      geoObjects: (state: GeoObjectState): Data<GeoObject> => {
        const { hiddenIsolationZoneSubLayers } = state;
        return Object.values(state.data).reduce((acc, geoObject) => {
          if (geoObject.objectSubType && hiddenIsolationZoneSubLayers.includes(geoObject.objectSubType)) {
            return acc;
          }
          return { ...acc, [geoObject.id]: geoObject };
        }, {});
      },
    }),
    newFieldsSize(): number {
      return this.newFields.reduce((sum: number, field: Field) => sum + field.fieldSize, 0);
    },
    fieldsVisible(): Data<Field> {
      return this.visibleFields.reduce((fieldsVisible, id) => {
        const field = this.fields[id];
        if (field) {
          return {
            ...fieldsVisible,
            [id]: this.fields[id],
          };
        }
        return fieldsVisible;
      }, {});
    },
    activeFields(): Data<Field> {
      if (this.editFieldsActive) {
        return this.selectedFields
          .filter((fieldId) => this.currentFieldId !== fieldId)
          .reduce(
            (activeFields, id) => ({
              ...activeFields,
              [id]: this.fields[id],
            }),
            {},
          );
      }

      if (!this.createFieldsActive) {
        const selectedAndVisibleFields = this.selectedFields.filter((id) => this.visibleFields.includes(id));
        return selectedAndVisibleFields.reduce((activeFields, id) => {
          const field = this.fields[id];
          if (field) {
            return {
              ...activeFields,
              [id]: this.fields[id],
            };
          }
          return activeFields;
        }, {});
      }
      return this.newFields
        .filter((field) => field.id !== this.currentFieldId)
        .reduce(
          (activeFields, field) => ({
            ...activeFields,
            [field.id]: field,
          }),
          {},
        );
    },
    defaultFields(): Data<Field> {
      if (!this.createFieldsActive) {
        const activeFieldsSet = new Set(Object.keys(this.activeFields));
        const editableFieldSet = new Set(Object.keys(this.editableFields));
        return Object.values(this.fieldsVisible).reduce(
          (defaultFields, field) =>
            !activeFieldsSet.has(field.id) && !editableFieldSet.has(field.id)
              ? { ...defaultFields, [field.id]: field }
              : defaultFields,
          {},
        );
      }
      return {};
    },
    disabledFields(): Data<Field> {
      if (this.createFieldsActive) {
        const activeFieldsSet = new Set(Object.keys(this.activeFields));
        return Object.values(this.fieldsVisible).reduce(
          (defaultFields, field) =>
            !activeFieldsSet.has(field.id) ? { ...defaultFields, [field.id]: field } : defaultFields,
          {},
        );
      }

      if (this.editFieldsActive) {
        const activeFieldsSet = new Set(Object.keys(this.activeFields));
        const editableFieldSet = new Set(Object.keys(this.editableFields));
        return Object.values(this.fieldsVisible).reduce(
          (defaultFields, field) =>
            !activeFieldsSet.has(field.id) && !editableFieldSet.has(field.id)
              ? { ...defaultFields, [field.id]: field }
              : defaultFields,
          {},
        );
      }

      return {};
    },
    editableFields(): Data<Field> {
      if (this.editFieldsActive && this.currentFieldId) {
        return {
          [this.currentFieldId]: {
            ...this.fields[this.currentFieldId],
            ...(this.geoDataChanges[this.currentFieldId] != null ? this.geoDataChanges[this.currentFieldId] : {}),
          },
        };
      }

      return this.newFields
        .filter((field: Field) => field.id === this.currentFieldId)
        .reduce(
          (activeFields: any, field: any) => ({
            ...activeFields,
            [field.id]: field,
          }),
          {},
        );
    },
    companies(): Company[] {
      return (this.$store.state as RootState).auth.currentCompanies;
    },
    customers(): Data<Customer> {
      return (this.$store.state as RootState).customers.data;
    },
    varieties(): Data<PlantVariety> {
      return (this.$store.state as RootState).varieties.data;
    },
    crops(): Data<Product> {
      return (this.$store.state as RootState).products.crops.data;
    },
    activities(): Data<Activity> {
      return (this.$store.state as RootState).activities.data;
    },
    activityTypes(): Data<ActivityType> {
      return (this.$store.state as RootState).activityTypes.data;
    },
    featureFieldsNDVIMeanEnabled(): Boolean {
      return (
        this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_FIELDS_NDVI_MEAN) &&
        this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_FIELDS_NDVI_MEAN)
      );
    },
    featureLandCadastresEnabled(): Boolean {
      return (
        this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_MAP_LAND_CADASTRE) &&
        this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_MAP_LAND_CADASTRE)
      );
    },
    featureGISObjectsEnabled(): Boolean {
      return (
        this.$store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_MAP_GEO_OBJECTS) &&
        this.$store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_MAP_GEO_OBJECTS)
      );
    },
    bufferFeatures(): Feature<turf.Polygon>[] {
      return this.selectedFields
        .map((fieldId: string) => this.$store.state.fields.data[fieldId])
        .map((field: Field) => field?.fieldContour?.geometry)
        .filter(notNullOrUndefined)
        .flatMap((geometry: GeoJson) => geometry.coordinates)
        .map((coordinates: Position[]) => turf.lineString(coordinates))
        .map((feature: Feature<LineString>) =>
          turf.buffer<LineString>(feature, this.checkDistance, { units: 'meters' }),
        )
        .filter(notNullOrUndefined);
    },
    bufferFields(): google.maps.MVCArray<google.maps.LatLng>[] {
      return this.bufferFeatures
        .flatMap((feature: Feature<turf.Polygon>) => feature.geometry.coordinates)
        .map(toGoogleMapsPath);
    },
    fieldsToWarn(): google.maps.MVCArray<google.maps.LatLng>[] {
      return Object.values(this.fieldUtilization)
        .map((field: FieldUtilization) => field.geometry)
        .filter(this.intersectionWithFieldBuffers)
        .flatMap((geometry: Polygon) => geometry.coordinates)
        .map(toGoogleMapsPath);
    },
  },
  watch: {
    selectedFields(val, old) {
      if (!this.reCenterOnFieldUpdate && val.length !== old.length) {
        this.reCenterOnFieldUpdate = true;
      }
    },
    newFields() {
      this.$emit('update:newFields', this.newFields);
    },
    createFieldsActive(val: boolean) {
      if (!val) {
        this.landCadastres = {};
        this.fieldUtilization = {};
        this.$emit('update:currentFieldId', null);
      } else {
        this.reCenterOnFieldUpdate = false;
      }
    },
    editFieldsActive(val) {
      if (val) this.reCenterOnFieldUpdate = false;
    },
    splitFieldLineValid(valid) {
      this.$emit('update:splitFieldLineValid', valid);
    },
    pendingNdvi(fields: Field[]) {
      this.loadNdvi(fields);
    },
    pendingMbi(fields: Field[]) {
      this.loadMbi(fields);
    },
  },
});
